<template>
  <div class="terminal-container">
    <div class="terminal">
      <div class="terminal-body">
        <pre class="terminal-output">
          <span v-for="(entry, index) in history" :key="index" :class="entry.type">
            <span class="output-entry">
              <span v-html="entry.text"></span>
            </span>
          </span>
        </pre>
        <div class="terminal-input-line">
          <span class="terminal-prompt">$</span>
          <input class="terminal-input" ref="input" v-model="command" @keydown.enter="executeCommand" :disabled="waitingForResponse" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import store from "@/store";

export default {
  data() {
    return {
      command: '',
      history: [],
      waitingForResponse: false,
    };
  },
  methods: {
    async executeCommand() {
      if (this.waitingForResponse) {
        return; // Don't execute new command if still waiting for response
      }

      if (this.command.trim() === '') {
        return; // Don't execute empty command
      }

      try {
        this.waitingForResponse = true;
        const headers = {
          'Content-Type':'application/json',
          'token': store.getters.token
        }
        const response = await axios.post('https://api.landofbytes.com/service/redis/cli',{ command: this.command },{headers});
        console.log(response);
        const commandEntry = {
          type: 'command',
          text: `$ ${this.command}`,
        };
        const resultEntry = {
          type: 'result',
          text: response.data,
        };
        this.history.push(commandEntry, resultEntry);
      } catch (error) {
        const commandEntry = {
          type: 'command',
          text: `$ ${this.command}`,
        };
        const errorEntry = {
          type: 'result',
          text: `Error: ${error.message}`,
        };
        this.history.push(commandEntry, errorEntry);
      } finally {
        this.command = '';
        this.waitingForResponse = false;
        this.$nextTick(() => {
          this.$refs.input.focus(); // Set focus back to the input field
        });
      }
    }

  },
};
</script>

<style>
.terminal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  background-color: #000;
  border-radius: 10px;
}

.terminal {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: #000;
  color: #fff;
  font-family: monospace;
  overflow: hidden;
}

.terminal-body {
  height: 100%;
  width: 100%;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 15%;
}

.terminal-output {
  width: 100%;
  margin: 0;
  padding-top: 2px;
  white-space: normal;
  word-wrap: break-word;
  flex-grow: 1; /* Take remaining vertical space */
}

.output-entry {
  display: flex;
  align-items: flex-start;
}

.command {
  color: #6be234;
  margin: 5px;
}

.result {
  color: #fff;
  margin: 5px;
}

.terminal-input-line {
  width: 100%;
  display: flex;
  outline: none;
  align-items: center;
  margin: 0;
  padding-top: 4px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.terminal-prompt {
  color: #6be234;
  margin-right: 4px;
  font-size: large;
}

.terminal-input {
  width: 100%;
  background: none;
  border: none;
  color: #fff;
  outline: none;
  font-family: monospace;
  margin: 0;
  padding-top: 4px;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: large;
}
</style>
